<template>
  <ul class="breadcrumbs overflow-hidden d-block text-truncate">
    <li class="d-inline me-1">
      <fa-icon :icon="faLocationArrow" class="me-4" />
      <nuxt-link
        class="text-gp-mouse hover:text-gp-primary text-decoration-none transition-color"
        :to="`/${locale}`"
        >{{ t('app/home') }}</nuxt-link
      >
      &nbsp; /
    </li>

    <li
      v-for="(crumb, index) in crumbs"
      :key="crumb.link"
      class="d-inline me-1"
    >
      <nuxt-link
        v-if="index < crumbs.length - 1"
        class="text-gp-mouse hover:text-gp-primary text-decoration-none transition-color"
        :to="`/${locale}/${crumb.link}`"
      >
        {{ crumb.title }}
      </nuxt-link>
      <span v-else class="text-rhino fw-bold">{{ crumb.title }}</span>
      <template v-if="index < crumbs.length - 1"> &nbsp; / </template>
    </li>
  </ul>
</template>

<script lang="ts">
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons';
import type { PropType } from 'vue';
import type { BreadCrumb } from '~/components/common/types';

export default defineComponent({
  props: {
    crumbs: {
      type: Array as PropType<Array<BreadCrumb>>,
      default: () => [],
    },
  },
  setup() {
    const { t, locale } = useI18n();

    return {
      faLocationArrow,

      t,
      locale,
    };
  },
});
</script>
